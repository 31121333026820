import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";

import ProtectedRoute from "./common/components/ProtectedRoute";
import OpticianDashboard from "./components/Optician/OpticianDashboard";
import { AuthProvider, useAuth } from "./context/AuthContext";
import PricingPlans from "./components/Optician/PricingPlans";
import PaymentForm from "./components/Optician/PaymentForm";
import SponsorList from "./components/Optician/Sponsors/Sponsors";
import AddSponsor from "./components/Optician/Sponsors/AddSponsors";
import Profile from "./components/Optician/Profil/Profil";
import Support from "./features/support/pages/Support";
import MyCustomers from "./components/MyCustomers/MyCustomers";
import Vouchers from "./features/vouchers/pages/Vouchers";
import {
  ForgotPassword,
  Login,
  Register,
  RegisterUser,
  ResetPassword,
} from "./features/auth/pages";
import PublicSectorForm from "./features/public-form/pages/PublicSectorForm";
import QRCode from "./features/qr-code/pages/QRCode";
import UseMobileApp from "./features/redirect-user/pages/UseMobileApp";
import NewIban from "./components/Optician/NewIban";
import MyInvoices from "./features/invoices/pages/MyInvoices";
import Worker from "./features/worker/pages/Worker";
import Settings from "./features/settings/pages/Settings";
import api from "./services/api";

function App() {
  const [qrCodeRoutes, setQrCodeRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const stripePromise = loadStripe(
    process.env.REACT_APP_ENV === "production"
      ? "pk_live_51PLuQiHzL8gubNCfOBaaohZ0Vwz2Rti3HVD5YSdxui9saiRuPA1T0XRfNKEfvmlxSeKBHJX3uGBcOGdd769RoChF00t2XrnVLW"
      : "pk_test_51PLuQiHzL8gubNCf7IJkAafxB2pidgeYPPcod5oKvoLddSxsZb2hkRHN68zUuRbf97rPmWHH31ir0IUHC27db5Yc00BxBPLFJB"
  );

  useEffect(() => {
    const fetchQRCodes = async () => {
      try {
        const response = await api.get("/qr-codes");
        setQrCodeRoutes(response.data);
      } catch (error) {
        console.error("Erreur lors du chargement des QR codes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQRCodes();
  }, []);

  console.log(qrCodeRoutes);

  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <div className="App">
            <div>
              {isLoading ? (
                <div>Chargement...</div>
              ) : (
                <Routes>
                  <Route path="/" element={<DefaultRedirect />} />
                  <Route path="/public-form" element={<PublicSectorForm />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/sign-up" element={<RegisterUser />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  {qrCodeRoutes.map((qrCode) => (
                    <Route
                      key={qrCode.id}
                      path={qrCode.url_path}
                      element={<QRCode creatorId={qrCode.optician_id} />}
                    />
                  ))}
                  <Route path="/app-mobile" element={<UseMobileApp />} />
                  <Route
                    path="/optician-dashboard"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <OpticianDashboard />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/customers"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <MyCustomers />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/invoices"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <MyInvoices />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/plan"
                    element={<ProtectedRoute element={<PricingPlans />} />}
                  />
                  <Route
                    path="/payment"
                    element={<ProtectedRoute element={<PaymentForm />} />}
                  />
                  <Route
                    path="/sponsors"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <SponsorList />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/workers"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <Worker />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/bons"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <Vouchers />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/profil"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <Profile />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/support"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOptician>
                            <Support />
                          </RequireOptician>
                        }
                      />
                    }
                  />
                  <Route
                    path="/optician-support"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <Support />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/sponsors/add"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <AddSponsor />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/change-card"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <NewIban />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute
                        element={
                          <RequireOpticianRole>
                            <Settings />
                          </RequireOpticianRole>
                        }
                      />
                    }
                  />
                  <Route path="/default" element={<DefaultRedirect />} />
                  <Route
                    path="*"
                    element={<QRCodeOrDefault qrCodeRoutes={qrCodeRoutes} />}
                  />
                </Routes>
              )}
            </div>
          </div>
        </Router>
      </Elements>
    </AuthProvider>
  );
}

const DefaultRedirect = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (currentUser.role === "optician") {
    return <Navigate to="/optician-dashboard" />;
  }

  if (currentUser.role !== "optician") {
    return <Navigate to="/app-mobile" />;
  }

  return children;
};
const RequireOpticianRole = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (currentUser.role !== "optician") {
    return <Navigate to="/default" />;
  }

  console.log(currentUser);

  if (
    currentUser.plan_level !== 1 &&
    (!currentUser.stripe_subscription_id || !currentUser.stripe_customer_id)
  ) {
    return <Navigate to="/plan" />;
  }

  return children;
};

const RequireOptician = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (currentUser.role !== "user") {
    return <Navigate to="/default" />;
  }

  return children;
};

// Nouveau composant pour traiter les URLs de QR code dynamiquement
const QRCodeOrDefault = ({ qrCodeRoutes }) => {
  const location = window.location.pathname;

  // Vérifier si l'URL actuelle correspond à un QR code connu
  const matchingQRCode = qrCodeRoutes.find((qr) => qr.url_path === location);

  if (matchingQRCode) {
    return <QRCode creatorId={matchingQRCode.optician_id} />;
  }

  // Si aucune correspondance, rediriger vers default
  return <Navigate to="/default" replace />;
};

export default App;
