import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { useAuth } from "../../../context/AuthContext";
import { Toaster } from "react-hot-toast";
import Page from "../../../layouts/Page";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";

interface PriceRange {
  id: number;
  min: number;
  max: number;
  reward: number;
}

const Settings = () => {
  const [priceRanges, setPriceRanges] = useState<PriceRange[]>([]);
  const [loading, setLoading] = useState(true);
  const [newRange, setNewRange] = useState({
    min: "",
    max: "",
    reward: "",
  });

  const fetchPriceRanges = async () => {
    setLoading(true); 
    try {
      const token = localStorage.getItem("token");
      const response = await api.get("/price-ranges", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPriceRanges(response.data);
    } catch (error) {
      toast.error("Erreur lors du chargement des tranches de prix");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPriceRanges();
  }, []);

  const handleAddRange = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await api.post(
        "/price-ranges",
        {
          min: Number(newRange.min),
          max: Number(newRange.max),
          reward: Number(newRange.reward),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Tranche de prix ajoutée avec succès");
      fetchPriceRanges();
      setNewRange({ min: "", max: "", reward: "" });
    } catch (error) {
      toast.error("Erreur lors de l'ajout de la tranche de prix");
    }
  };

  const handleDeleteRange = async (index: number) => {
    try {
      const token = localStorage.getItem("token");
      await api.delete(`/price-ranges/${index}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Tranche de prix supprimée avec succès");
      fetchPriceRanges();
    } catch (error) {
      toast.error("Erreur lors de la suppression de la tranche de prix");
    }
  };

  return (
    <Page>
      <Helmet>
        <title>Paramètres | Fideliz</title>
      </Helmet>
      <Toaster />

      <div className="mt-6 border-t-2 border-l-neutral-800">
        <div className="flex justify-between items-center mb-8 px-8 py-6 mt-6 bg-black rounded-xl">
          <div>
            <h2 className="text-xl font-bold text-white">Paramètres</h2>
            <p className="text-white text-xs font-semibold mt-2">
              Gestion des tranches de prix et récompenses
            </p>
          </div>
        </div>

        <div className="w-full flex rounded-xl flex-wrap justify-center bg-white p-8">
          {/* Formulaire d'ajout */}
          <form onSubmit={handleAddRange} className="w-full mb-8">
            <h3 className="text-lg font-semibold mb-4">
              Ajouter une tranche de prix
            </h3>
            <div className="flex gap-4">
              <input
                type="number"
                placeholder="Min (€)"
                value={newRange.min}
                onChange={(e) => setNewRange({ ...newRange, min: e.target.value })}
                className="border rounded-lg p-2"
                required
              />
              <input
                type="number"
                placeholder="Max (€)"
                value={newRange.max}
                onChange={(e) => setNewRange({ ...newRange, max: e.target.value })}
                className="border rounded-lg p-2"
                required
              />
              <input
                type="number"
                placeholder="Récompense (€)"
                value={newRange.reward}
                onChange={(e) =>
                  setNewRange({ ...newRange, reward: e.target.value })
                }
                className="border rounded-lg p-2"
                required
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
              >
                Ajouter
              </button>
            </div>
          </form>

          {/* Liste des tranches */}
          <div className="w-full">
            <h3 className="text-lg font-semibold mb-4">
              Tranches de prix existantes
            </h3>
            <div className="grid grid-cols-4 gap-4 font-bold mb-2">
              <div>Minimum</div>
              <div>Maximum</div>
              <div>Récompense</div>
              <div>Actions</div>
            </div>
            {loading ? (
              <div>Chargement...</div>
            ) : (
              priceRanges.map((range, index) => (
                <div
                  key={index}
                  className="grid grid-cols-4 gap-4 items-center py-2 border-t"
                >
                  <div>{range.min}€</div>
                  <div>{range.max}€</div>
                  <div>{range.reward}€</div>
                  <button
                    onClick={() => handleDeleteRange(index)}
                    className="bg-red-500 text-white px-3 py-1 rounded-lg w-fit"
                  >
                    Supprimer
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Settings;
