import React from "react";
import {
  UseFormHandleSubmit,
  UseFormRegister,
  FieldErrors,
} from "react-hook-form";
import InputField from "./InputField";

interface AuthFormProps {
  onSubmit: (data: any) => void;
  register: UseFormRegister<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  errors: FieldErrors<any>;
  fields: {
    label: string;
    type: string;
    name: string;
    validation?: Record<string, any>;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    showSuggestions?: boolean;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    labelExtra?: React.ReactNode;
  }[];
  addressSuggestions?: any[];
  handleAddressSelect?: (suggestion: any) => void;
  submitButtonText: string;
  isSubmitting?: boolean;
}

const AuthForm: React.FC<AuthFormProps> = ({
  onSubmit,
  register,
  handleSubmit,
  errors,
  fields,
  addressSuggestions,
  handleAddressSelect,
  submitButtonText,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field) => (
        <div key={field.name} className="mb-2">
          {field.type === "checkbox" ? (
            <div className="flex items-center">
              <input
                type="checkbox"
                id={field.name}
                {...register(field.name, field.validation)}
                className="mr-2"
              />
              <label htmlFor={field.name} className="text-sm">
                {field.label}
                {field.labelExtra}
              </label>
            </div>
          ) : (
            <InputField
              label={field.label}
              type={field.type}
              name={field.name}
              register={register}
              error={errors[field.name]}
              validation={field.validation}
              onChange={field.onChange}
            />
          )}
          {field.showSuggestions &&
            addressSuggestions &&
            addressSuggestions.length > 0 && (
              <ul className="bg-white border border-gray-300 rounded mt-2 max-h-40 overflow-auto">
                {addressSuggestions.map((suggestion) => (
                  <li
                    key={suggestion.properties.id}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                    onClick={() =>
                      handleAddressSelect && handleAddressSelect(suggestion)
                    }
                  >
                    {suggestion.properties.label}
                  </li>
                ))}
              </ul>
            )}
        </div>
      ))}


      <button
        type="submit"
        disabled={isSubmitting}
        style={{ background: isSubmitting ? "#999" : "#0166FF" }}
        className={`w-full font-semibold text-white py-2 mt-3 rounded-xl ${
          isSubmitting ? "" : "hover:bg-blue-300"
        }`}
      >
        {submitButtonText}
      </button>
    </form>
  );
};

export default AuthForm;
