import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { useForm } from "react-hook-form";
import api from "../../services/api";
import { useAuth } from "../../context/AuthContext";
import {
  FaHandshake,
  FaUserFriends,
  FaCalendarDay,
  FaChevronDown,
  FaSearch,
  FaCashRegister,
  FaEye,
  FaEyeSlash,
  FaTimes,
  FaEye as FaEyeIcon,
  FaHeadphones
} from "react-icons/fa";
import "./OpticianDashboard.css";
import Page from "../../layouts/Page";
import ReferralSuccessChart from "./components/ReferralSuccessChart";
import RankingChart from "./components/RankingChart";
import { parse, isSameDay, isSameMonth, isSameYear, format } from "date-fns";
import { fr } from "date-fns/locale";
import LivredChart from "./components/LivredChart";
import DateSelector from "../../common/components/DateSelector";
import fidelizxlyleoo from "../../common/assets/fidelizxlyleoo.png"

function OpticianDashboard() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [defaultClients, setDefaultClients] = useState([]);
  const [messages, setMessages] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedClientMessages, setSelectedClientMessages] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [error, setError] = useState("");
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [monthlyVisitors, setMonthlyVisitors] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState("");
  const [clientIdToReject, setClientIdToReject] = useState(null);
  const [showRevenueModal, setShowRevenueModal] = useState(false);
  const { currentUser } = useAuth();
  const [totalRevenueThisMonth, setTotalRevenueThisMonth] = useState(0);
  const [sortKey, setSortKey] = useState("date");
  const [searchQuery, setSearchQuery] = useState("");
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [clientIdToApprove, setClientIdToApprove] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [totalDayVisits, setTotalDayVisits] = useState(0);
  const [showRevenue, setShowRevenue] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [stats, setStats] = useState({
    totalSales: 0,
    monthlySales: 0,
    dailyVisits: 0,
    monthlyRevenue: 0,
    clientAcceptanceRate: 0,
    topSponsors: [],
  });

  const [commissionInfo, setCommissionInfo] = useState({ amount: 0, percentage: 0 });

  const [rewardRanges, setRewardRanges] = useState([]);

  const loadStats = async (month) => {
    try {
      const token = localStorage.getItem("token");
      const formattedMonth = format(month, 'yyyy-MM');
      const response = await api.get('/opticians/stats', {
        params: {
          month: formattedMonth,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setStats(response.data);
    } catch (error) {
      console.error('Failed to load stats', error);
    }
  };

  const openInGoogleMaps = (address) => {
    const baseUrl = "https://www.google.com/maps/search/?api=1&query=";
    const formattedAddress = encodeURIComponent(address);
    const mapsUrl = `${baseUrl}${formattedAddress}`;
    window.open(mapsUrl, "_blank");
  };

  useEffect(() => {
    loadStats(selectedMonth);
  }, [selectedMonth]);

  console.log(stats);

  const isSeller = currentUser.seller_type === "seller";

  const toggleRevenueVisibility = () => {
    setShowRevenue(!showRevenue);
  };

  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem("token");
      const commission = currentUser.sector === "PIDEV" ? calculateCommission(data.revenue) : 0;
      
      await api.post(
        `/clients/approved-status/${clientIdToApprove}`,
        {
          revenue: data.revenue,
          isHealth100: data.isHealth100,
          isUnifocal: data.isUnifocal,
          isMixte: data.isMixte,
          sendSms: data.sendSms,
          commission: commission,
          prestationType: data.prestationType
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setShowRevenueModal(false);
      reset();
      fetchClients();
    } catch (error) {
      setError("Failed to update client status");
      console.error("Failed to update client status", error);
    }
  };

  function capitalizeFirstLetter(string) {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleActionClick = (clientId) => {
    setShowActions(showActions === clientId ? null : clientId);
  };

  const fetchClients = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get("/opticians/clients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedClients = sortClientsByDate(response.data.clients);
      setClients(sortedClients);
      const clientsWithActions = isSeller
        ? sortedClients.filter((client) => client.status !== "delivered" && client.status !== 'expired' && client.status !== 'waiting')
        : sortedClients.filter(
          (client) =>
            client.status !== "approved" && client.status !== "rejected"
        );
      setCustomFields(response.data.customFields);
      setFilteredClients(clientsWithActions);
      setDefaultClients(clientsWithActions);

      const approvedClients = sortedClients.filter((client) =>
        isSeller ? client.status === "payed" : client.status === "approved"
      );
      setTotalVisitors(approvedClients.length);

      const currentDate = new Date();

      const parseClientDelay = (delay) => {
        const [datePart, period] = delay.split(" ");
        return parse(datePart, "dd/MM/yyyy", new Date(), { locale: fr });
      };

      const monthlyVisitors = approvedClients.filter((client) => {
        const clientDate = new Date(client.updated_at);
        return (
          isSameMonth(clientDate, currentDate) &&
          isSameYear(clientDate, currentDate)
        );
      });
      setMonthlyVisitors(monthlyVisitors.length);

      const dailyVisitors = clientsWithActions.filter((client) => {
        if (!client.delay) return;
        const clientDate = parseClientDelay(client.delay);
        return isSameDay(clientDate, currentDate);
      });

      const dailySell = sortedClients.filter((client) => {
        if (!client.created_at) return;
        if (client.status === 'expired') return;
        if (client.status === 'waiting') return;
        const clientDate = new Date(client.created_at);
        return isSameDay(clientDate, currentDate);
      });
      setTotalDayVisits(isSeller ? dailySell.length : dailyVisitors.length);

      const revenueThisMonth = monthlyVisitors.reduce((total, client) => {
        return Number(total) + Number(client.money_generated);
      }, 0);

      console.log("ici", revenueThisMonth);

      setTotalRevenueThisMonth(revenueThisMonth);
    } catch (error) {
      setError("Failed to fetch clients");
      console.error("Failed to fetch clients", error);
    }
  };

  const fetchMessages = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get(`/messages/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const messages = response.data;
      const unreadMessages = messages.filter(
        (message) => !message.is_read && message.recipient_id === currentUser.id
      );

      setMessages(messages);
      setUnreadMessages(unreadMessages);
    } catch (error) {
      console.error("Failed to fetch messages", error);
    }
  };

  useEffect(() => {
    fetchClients();
    const userId = currentUser.id;
    fetchMessages(userId);
  }, []);

  const handleClientStatus = async (clientId, reason = "") => {
    try {
      const token = localStorage.getItem("token");
      await api.post(
        `/clients/${clientId}`,
        { reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchClients();
      fetchMessages(clientId);
    } catch (error) {
      setError("Failed to update client status");
      console.error("Failed to update client status", error);
    }
  };

  const handleRefundClient = async (clientId) => {
    try {
      const token = localStorage.getItem("token");
      await api.post(
        `/clients/refund-status/${clientId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowActions(null);
      fetchClients();
    } catch (error) {
      setError("Failed to update client status");
      console.error("Failed to update client status", error);
    }
  };

  const handleRejectClick = (clientId) => {
    setClientIdToReject(clientId);
    setShowModal(true);
    setShowActions(null);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setReason("");
  };

  const handleModalSubmit = () => {
    if (clientIdToReject) {
      handleClientStatus(clientIdToReject, reason);
      fetchClients();
      handleModalClose();
    }
  };

  const handleRevenueModalClose = () => {
    setShowRevenueModal(false);
  };

  const markMessagesAsRead = async (clientId) => {
    try {
      const token = localStorage.getItem("token");
      await api.post(
        `/messages/mark-as-read`,
        { client_id: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.client_id === clientId
            ? { ...message, is_read: true }
            : message
        )
      );

      setUnreadMessages((prevUnreadMessages) =>
        prevUnreadMessages.filter((message) => message.client_id !== clientId)
      );
    } catch (error) {
      console.error("Failed to mark messages as read", error);
    }
  };

  const translateStatus = (status, clientId) => {
    switch (status) {
      case "pending":
        return (
          <div className="bg-yellow-200 text-yellow-600 flex items-center justify-center px-2 py-1 rounded-lg text-center">
            <p className="font-semibold">Attente réponse</p>
          </div>
        );
      case "approved":
        return (
          <div className="bg-green-200 text-green-600 flex items-center justify-center px-2 py-1 rounded-lg">
            <p className="font-semibold">Approuvé</p>
          </div>
        );
      case "rejected":
        return (
          <div className="bg-red-200 text-red-600 flex items-center justify-center px-2 py-1 rounded-lg">
            <p className="font-semibold">Rejeté</p>
          </div>
        );
      case "refund":
        return (
          <div className="flex justify-center w-full md:w-auto mt-2 md:mt-0 relative">
            <button
              onClick={() => handleActionClick(clientId)}
              className="bg-blue-200 text-blue-600 flex items-center w-full justify-center px-2 py-1 rounded-lg"
            >
              <p className="font-semibold">En cours</p>
              <FaChevronDown className="ml-2" />
            </button>
            {showActions === clientId && (
              <div className="absolute bg-blue-200 border py-2 rounded-lg mt-2 top-5 px-2 z-10 right-0">
                <button
                  onClick={() => handleApproveClick(clientId)}
                  className="block w-full py-1 px-4 bg-white text-green-700 hover:bg-green-300 rounded-lg"
                >
                  Approuver
                </button>
                <button
                  onClick={() => handleRejectClick(clientId)}
                  className="block w-full py-1 px-4 bg-white text-red-600 hover:bg-red-300 rounded-lg my-2"
                >
                  Refuser
                </button>
              </div>
            )}
          </div>
        );
      case "payed":
        return (
          <div className="relative">
            <button
              onClick={() => handleActionClick(clientId)}
              className="bg-blue-200 text-blue-600 flex items-center w-full justify-center px-2 py-1 rounded-lg"
            >
              <p className="font-semibold">Att. livraison</p>
              <FaChevronDown className="ml-2" />
            </button>
            {showActions === clientId && (
              <div className="absolute bg-blue-200 border py-2 rounded-lg mt-2 top-5 px-2 z-10 right-0">
                <button
                  onClick={() => handleDeliveredClick(clientId)}
                  className="block w-full py-1 px-4 bg-white text-green-700 hover:bg-green-300 rounded-lg"
                >
                  Commande livré
                </button>
              </div>
            )}
          </div>
        );
      case "match":
        return (
          <div className="bg-blue-200 text-center text-blue-600 flex items-center justify-center px-2 py-1 rounded-lg">
            <p className="font-semibold">Bloqué par fideliz</p>
          </div>
        );
      default:
        return status;
    }
  };

  const handleBubbleClick = (client) => {
    const clientMessages = messages.filter(
      (message) => message.client_id === client.id
    );
    setSelectedClientMessages(clientMessages);
    setSelectedClient(client);
    setDrawerOpen(true);
    markMessagesAsRead(client.id);
    setShowActions(null);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedClientMessages([]);
    setSelectedClient(null);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `Le ${day}/${month}/${year} à ${hours}h${minutes}`;
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const messageData = {
      client_id: selectedClient.id,
      content: newMessage,
      recipient_id: selectedClient.sponsor_id,
      is_read: false,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await api.post("/messages/create", messageData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const message = response.data;

      setSelectedClientMessages([...selectedClientMessages, message]);
      setNewMessage("");
      fetchMessages()
      console.log("Message sent:", message);
    } catch (error) {
      console.error("Failed to send message", error);
    }
  };

  const handleApproveClick = (clientId, client) => {
    setClientIdToApprove(clientId);
    setSelectedClient(client);
    setShowRevenueModal(true);
    setShowActions(null);
  };

  const handleDeliveredClick = async (clientId) => {
    setShowActions(null);
    const token = localStorage.getItem("token");
    await api.post(
      `/clients/delivered-status/${clientId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    await fetchClients()
    await loadStats(selectedMonth);
  };

  const openModalForClient = (client) => {
    setSelectedClient(client);
    setIsModalOpen(true);
  };


  const handleSort = (key) => {
    if (sortKey === key) {
      setSortKey("");
      setFilteredClients(defaultClients);
    } else {
      setSortKey(key);
      let sortedClients = [...filteredClients];
      if (key === "date") {
        sortedClients.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } else if (key === "sponsor") {
        sortedClients.sort((a, b) =>
          a.sponsor_name.localeCompare(b.sponsor_name)
        );
      } else if (key === "visit") {
        sortedClients.sort((a, b) => {
          const parseDate = (dateStr) => {
            if (!dateStr) return Infinity;
            const [day, month, yearAndTime] = dateStr.split("/");
            const [year] = yearAndTime.split(" ");
            return new Date(`${year}-${month}-${day}`);
          };

          const dateA = parseDate(a.delay);
          const dateB = parseDate(b.delay);

          return dateA - dateB;
        });
      }
      setFilteredClients(sortedClients);
    }
  };

  const sortClientsByDate = (clients) => {
    return clients.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const query = e.target.value.toLowerCase();
    const filtered = defaultClients.filter(
      (client) =>
        client.first_name.toLowerCase().includes(query) ||
        client.last_name.toLowerCase().includes(query)
    );
    setFilteredClients(filtered);
  };

  useEffect(() => {
    const fetchRewardRanges = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get('/price-ranges', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setRewardRanges(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des tranches de prix', error);
      }
    };

    fetchRewardRanges();
  }, []);

  const calculateCommission = (revenue) => {
    if (!revenue || !rewardRanges.length) return 0;
    const revenueNum = Math.ceil(Number(revenue));
    
    // Trier les tranches par min croissant pour avoir la dernière tranche à la fin
    const sortedRanges = [...rewardRanges].sort((a, b) => a.min - b.min);
    const lastRange = sortedRanges[sortedRanges.length - 1];
    
    // Si le montant est supérieur au dernier max, utiliser la dernière récompense
    if (lastRange && revenueNum >= lastRange.min) {
      return lastRange.reward;
    }
    
    // Sinon chercher la tranche correspondante
    const matchingRange = sortedRanges.find(range => 
      revenueNum >= range.min && (revenueNum <= range.max || range.max === null)
    );

    return matchingRange ? matchingRange.reward : 0;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Page>
      <Helmet>
        <title>Tableau de bord | Fideliz</title>
      </Helmet>
      
      <div className="flex flex-col items-center mt-2">
        <DateSelector
          currentMonth={selectedMonth}
          onChange={setSelectedMonth}
          startMonth={stats.startMonth}
          endMonth={stats.endMonth}
        />
        <div className="text-base flex flex-col md:flex-row md:space-y-0 space-y-4 justify-between w-full mb-6">

          <div className="flex flex-col justify-center bg-white py-6 px-6 rounded-xl w-full md:w-1/4 md:mr-4">
            <div className="bg-blue-500 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer mb-4">
              <FaHandshake className="text-white text-xl" />
            </div>
            <p className="font-semibold text-lg">Total des ventes</p>
            <div className="mt-4 flex items-center justify-between">
              <p className="font-bold text-3xl">{stats.totalSales}</p>
              <svg
                width="108"
                height="56"
                viewBox="0 0 108 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.57153 50.5691C2.57153 50.5691 -0.0489788 49.3095 5.93248 52.5042C25.6277 63.0233 29.1717 1.94225 51.5 1.94238C68.9128 1.94249 76.3407 40.6912 92.6614 34.7565C100.278 31.987 105.721 26.0501 105.721 26.0501"
                  stroke="url(#paint0_linear_1_2102)"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_2102"
                    x1="105.721"
                    y1="25.0122"
                    x2="1.99999"
                    y2="50.7602"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#8A39E1" stopOpacity="0" />
                    <stop offset="0.458333" stopColor="#8A39E1" />
                    <stop offset="1" stopColor="#8A39E1" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="flex flex-col justify-center bg-white py-6 px-6 rounded-xl w-full md:w-1/4 md:mr-4">
            <div className="bg-orange-400 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer mb-4">
              <FaUserFriends className="text-white text-xl" />
            </div>
            <p className="font-semibold text-lg">Ventes du mois</p>
            <div className="mt-4 flex items-center justify-between">
              <p className="font-bold text-3xl">{stats.monthlySales}</p>
              <svg
                width="121"
                height="64"
                viewBox="0 0 121 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.49341 58.8878C2.49341 58.8878 -0.127104 57.6282 5.85435 60.8229C25.5496 71.342 14.1229 1.49987 36.4512 1.5C53.864 1.5001 57.8604 39.079 74.1811 33.1443C81.7976 30.3747 81.0238 27.5798 86.7647 27.5798C92.5394 27.5798 91.7822 40.3951 97.6077 40.3951C100.524 40.3951 105.239 30.5024 107.689 29.2651C115.139 25.5024 120.801 38.2736 120.801 38.2736"
                  stroke="url(#paint0_linear_1_2119)"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_2119"
                    x1="105.643"
                    y1="33.3315"
                    x2="1.92187"
                    y2="59.0796"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF3A75" stopOpacity="0" />
                    <stop offset="0.458333" stopColor="#FF3A75" />
                    <stop offset="1" stopColor="#FF3A75" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          
          <div className="flex flex-col justify-center bg-white py-6 px-6 rounded-xl w-full md:w-1/4 md:mr-4">
            <div className="bg-pink-300 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer mb-4">
              <FaCalendarDay className="text-white text-xl" />
            </div>
            <p className="font-semibold text-lg">
              {currentUser.sector !== "OPTIQUE" ? "Appels du jour" : (isSeller ? "Ventes du jour" : "Visites du jour")}
            </p>
            <div className="mt-4 flex items-center justify-between">
              <p className="font-bold text-2xl">{totalDayVisits}</p>
              <svg
                width="108"
                height="56"
                viewBox="0 0 108 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.57153 50.5691C2.57153 50.5691 -0.0489788 49.3095 5.93248 52.5042C25.6277 63.0233 29.1717 1.94225 51.5 1.94238C68.9128 1.94249 76.3407 40.6912 92.6614 34.7565C100.278 31.987 105.721 26.0501 105.721 26.0501"
                  stroke="url(#paint0_linear_1_2102)"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_2102"
                    x1="105.721"
                    y1="25.0122"
                    x2="1.99999"
                    y2="50.7602"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#8A39E1" stopOpacity="0" />
                    <stop offset="0.458333" stopColor="#8A39E1" />
                    <stop offset="1" stopColor="#8A39E1" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="flex flex-col justify-between bg-blue-500 py-6 px-6 rounded-xl w-full md:w-1/4">
            <div>
              <div className="bg-blue-300 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer mb-4">
                <FaCashRegister className="text-white text-xl" />
              </div>
              <p className="font-normal text-lg text-white">
                CA généré ce mois
              </p>
            </div>
            <div className="flex items-center">
              <p
                className={`font-bold text-3xl text-white ${showRevenue ? "" : "blur-sm"
                  }`}
              >
                {stats.monthlyRevenue.toFixed(2)} €
              </p>
              <div
                className="ml-4 cursor-pointer"
                onClick={toggleRevenueVisibility}
              >
                {showRevenue ? (
                  <FaEyeSlash className="text-white text-2xl" />
                ) : (
                  <FaEye className="text-white text-2xl" />
                )}
              </div>
            </div>
          </div>
        </div>

        {currentUser.sector === "OPTIQUE" && (
          <div className="w-full flex flex-col md:flex-row bg-gradient-to-r bg-black rounded-xl p-4 md:p-6 mb-6 relative">
            <div className="absolute left-0 right-0 top-0 flex justify-center md:justify-start md:left-24">
              <div className="bg-white text-blue-600 text-xs md:text-sm font-bold px-3 md:px-5 py-1 rounded-b-lg">
                Partenariat exclusif
              </div>
            </div>
            
            <div className="md:hidden flex justify-center mb-4 mt-8 py-4">
              <img 
                src={fidelizxlyleoo}
                alt="Lyleoo - Solution digitale de téléexpertise" 
                className="h-20 object-contain"
              />
            </div>

            <div className="hidden md:flex items-center justify-center w-1/5">
              <img 
                src={fidelizxlyleoo}
                alt="Lyleoo - Solution digitale de téléexpertise" 
                className="w-52 h-32 object-contain"
              />
            </div>

            <div className="flex-1 md:w-3/5 md:px-6 flex flex-col items-center justify-center text-center">
              <p className="text-white text-xs md:text-base px-2 md:px-0 leading-relaxed">
                <span className="text-xs md:text-base"><strong>Lyleoo</strong> est la première solution digitale sécurisée de <strong>téléexpertise asynchrone</strong> permettant de faire face aux longs délais d'attente chez l'ophtalmologiste. Elle permet d'obtenir :</span> <br/><br/>
                <span className="text-xs md:text-sm">
                  👨‍⚕️ Un <strong>avis médical</strong><br/>
                  💡 Des <strong>conseils de prévention personnalisés</strong><br/>
                  👓 Des <strong>prescriptions d'équipements optiques</strong> (en l'absence de contre-indication)
                </span>
              </p>
            </div>

            <div className="hidden md:flex items-center justify-center w-1/5">
              <a 
                href="https://lyleoo.com/offre-fideliz"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-white text-blue-600 px-6 py-2 rounded-lg font-bold text-sm hover:bg-blue-50 transition-all duration-200 transform hover:scale-105 hover:shadow-lg border-2 border-transparent hover:border-white"
              >
                Profiter de l'offre
              </a>
            </div>

            <div className="md:hidden w-full mt-2">
              <a 
                href="https://lyleoo.com/partenaires/fideliz"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full text-center bg-white text-blue-600 px-4 py-2 rounded-lg font-bold text-sm hover:bg-blue-50 transition-all duration-200 transform hover:scale-105 hover:shadow-lg border-2 border-transparent hover:border-white"
              >
                Profiter de l'offre
              </a>
            </div>
          </div>
        )}

        {(stats.topSponsors.length !== 0 && stats.monthlySales !== 0) && (
          <div className="w-full flex flex-col md:flex-row mb-4">
            <div className="flex flex-col justify-center bg-white pt-4 px-6 rounded-xl w-full md:w-3/4 md:mr-4">
              <RankingChart isSeller={isSeller} data={stats.topSponsors} />
            </div>

            <div className="flex flex-col justify-between bg-white py-8 px-6 rounded-xl mt-4 md:mt-0 w-full md:w-1/4">
              <p className="mb-8 font-semibold text-lg text-center">
                {isSeller ? 'Vos livraisons' : "Taux d'acceptation des clients"}
              </p>
              {isSeller ? (
                <>
                  <LivredChart
                    totalCustomers={stats.monthlyTotal}
                    successRate={
                      clients.filter((client) => client.status === "delivered")
                        .length
                    }
                    pendingRate={
                      clients.filter((client) => client.status === "pending" || client.status === 'payed')
                        .length
                    }
                  />
                  <div className="flex flex-col items-center mt-2">
                    <div className="flex items-center mb-2">
                      <div
                        style={{ background: "#2F7EFF" }}
                        className="h-3 w-3 bg-red-400 rounded-full"
                      ></div>
                      <p className="text-sm ml-2 font-semibold">
                        Commandes livrées
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <div className="flex items-center">
                        <div
                          style={{ background: "#fdcb6e" }}
                          className="h-3 w-3 bg-red-400 rounded-full"
                        ></div>
                        <p className="text-sm ml-2 font-semibold">
                          Commandes à livrer
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ReferralSuccessChart
                    totalCustomers={stats.monthlyTotal}
                    successRate={
                      stats.successRate
                    }
                    pendingRate={
                      stats.pendingRate
                    }
                    refusalRate={
                      stats.refusalRate
                    }
                  />
                  <div className="flex justify-between mt-4">
                    <div className="flex items-center">
                      <div
                        style={{ background: "#2F7EFF" }}
                        className="h-3 w-3 bg-red-400 rounded-full"
                      ></div>
                      <p className="text-sm ml-2 font-semibold">
                        Clients acceptés
                      </p>
                    </div>

                    <div className="flex items-center">
                      <div
                        style={{ background: "#FF3A75" }}
                        className="h-3 w-3 bg-red-400 rounded-full"
                      ></div>
                      <p className="text-sm ml-2 font-semibold">
                        Clients refusés
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="flex items-center">
                      <div
                        style={{ background: "#fdcb6e" }}
                        className="h-3 w-3 bg-red-400 rounded-full"
                      ></div>
                      <p className="text-sm ml-2 font-semibold">
                        Clients en attente
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            
          </div>
        )}


        <div className="w-full flex flex-col md:flex-row justify-between rounded-t-xl items-center px-8 py-4 bg-white">
          <h2 className="font-semibold text-xl mb-4 md:mb-0 whitespace-nowrap">
            Vente en cours
          </h2>

          <div className="flex flex-col w-full md:flex-row md:items-center md:justify-end">
            <div className="mb-4 md:mb-0 md:mr-4 w-full md:w-auto flex justify-center md:justify-start">
              <div className="flex items-center w-full md:w-auto">
                <label className="text-sm font-medium mr-2 whitespace-nowrap">Trier par</label>
                <select
                  value={sortKey}
                  onChange={(e) => handleSort(e.target.value)}
                  className="w-full md:w-auto py-2 font-bold border-none focus:outline-none rounded-lg text-sm cursor-pointer bg-blue-100 text-blue-700 px-3"
                >
                  <option value="date">Date</option>
                  {!isSeller && (
                    <option value="visit">{currentUser.sector === 'OPTIQUE' ? 'Visite' : "Date d'appel"}</option>
                  )}
                  <option value="sponsor">{isSeller ? "Vendeur" : "Parrain"}</option>
                </select>
              </div>
            </div>

            <div className="relative flex items-center w-full md:w-auto">
              <FaSearch className="absolute ml-4 text-gray-500" />
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Rechercher un client"
                className="w-full md:w-auto pl-10 pr-4 py-2 text-sm px-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>

        <div className="w-full flex rounded-b-xl flex-wrap justify-center bg-white">
          <div className="w-full bg-white rounded p-3 my-2 flex flex-col md:flex-row md:justify-between md:items-center md:px-8">
            {/* En-têtes visibles uniquement sur desktop */}
            <div className="hidden md:block font-bold mb-2 md:mb-0 text-xs">Date</div>
            {currentUser.sector === "OPTIQUE_AUDIO" && (
              <div className="hidden md:block font-bold mb-2 md:mb-0 text-xs">Type</div>
            )}
            <div className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Nom</div>
            <div className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Prénom</div>
            <div className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Tél</div>
            {!isSeller && customFields.map((field) => {
              if (field.name === "delay") {
                if (currentUser.sector !== 'OPTIQUE') {
                  return (
                    <div key={field.name} className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Date d'appel</div>
                  )
                }
                return (
                  <div key={field.name} className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Visite</div>
                );
              }
              return (
                <div
                  key={field.name}
                  className="hidden md:block md:w-1/12 text-xs font-bold mb-2 md:mb-0"
                >
                  {field.dashboard_title}
                </div>
              );
            })}
            <div className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">{isSeller ? "Vendeur" : "Parrain"}</div>
            {isSeller && (
              <div className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Fiche de vente</div>
            )}
            <div className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Discussion</div>
            <div className="hidden md:block md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Actions</div>
          </div>
          {filteredClients.length === 0 && (
            <p className="font-bold mb-6 text-center mt-8">
              Aucun client pour le moment
            </p>
          )}
          {filteredClients.map((client) => (
            <div
              key={client.id}
              className="bg-white rounded border-t py-5 mx-8 w-full flex flex-col md:flex-row md:justify-between md:items-center"
            >
              <div className="text-center md:text-left mb-2 md:mb-0 font-semibold text-xs">
                {new Date(client.created_at).toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                })}
              </div>
              
              {currentUser.sector === "OPTIQUE_AUDIO" && (
                <div className="text-center md:text-left mb-2 md:mb-0 font-semibold text-xs">
                  {client.client_type === "AUDIO" ? (
                    <FaHeadphones className="text-orange-500 text-lg mx-auto md:mx-0" title="Audio" />
                  ) : (
                    <FaEyeIcon className="text-blue-500 text-lg mx-auto md:mx-0" title="Optique" />
                  )}
                </div>
              )}
              
              <div className="text-center md:text-left md:w-1/12 mb-2 md:mb-0 font-bold text-xs">
                {capitalizeFirstLetter(client.last_name)}
              </div>
              <div className="text-center md:text-left md:w-1/12 mb-2 md:mb-0 font-bold text-xs">
                {capitalizeFirstLetter(client.first_name)}
              </div>
              <div className="text-center md:text-left md:w-1/12 mb-2 md:mb-0 font-semibold text-xs">
                {client.phone}
              </div>
              {!isSeller && customFields.map((field) => {
                const value = client[field.name];
                if (field.name === "client_interest" && currentUser.sector === "PIDEV") {
                  return (
                    <div
                      key={field.name}
                      className="text-center md:text-left md:w-1/12 mb-2 md:mb-0 font-semibold text-xs"
                    >
                      <div onClick={() => openModalForClient(client)} className="bg-orange-200 cursor-pointer text-orange-600 flex items-center justify-center px-2 py-1 rounded-lg">
                        <p className="font-semibold text-xs">Afficher</p>
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    key={field.name}
                    className="text-center md:text-left md:w-1/12 mb-2 md:mb-0 font-semibold text-xs"
                  >
                    {value === "Oui"
                      ? "✔️"
                      : value === "Non"
                        ? "❌"
                        : value || "Non renseigné"}
                  </div>
                );
              })}
              <div className="text-center md:text-left md:w-1/12 mb-2 md:mb-0 font-semibold text-xs">
                {client.sponsor_name}
              </div>
              {isSeller && (
                <div className="text-center md:text-left md:w-1/12 mb-2 text-center md:mb-0 font-semibold text-xs">
                  <div onClick={() => openModalForClient(client)} className="bg-orange-200 cursor-pointer text-orange-600 flex items-center justify-center px-2 py-1 rounded-lg">
                    <p className="font-semibold text-xs">Afficher</p>
                  </div>
                </div>
              )}

              {isModalOpen && selectedClient && (currentUser.sector === "PIDEV" || isSeller) && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                  <div className="bg-white p-4 rounded-lg w-11/12 md:w-2/3 lg:w-1/2">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-xl font-semibold">
                        Fiche client de {selectedClient.first_name} {selectedClient.last_name}
                      </h2>
                      <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                        <FaTimes />
                      </button>
                    </div>

                    {selectedClient.articles && selectedClient.articles.length > 0 && (
                      <div className="mb-4">
                        <h3 className="text-sm font-bold mb-2">Articles</h3>
                        {selectedClient.articles.map((article, index) => (
                          <div key={index} className="flex flex-col items-center md:flex-row mb-2">
                            <span>{article.title} -</span>
                            <span className="ml-2 text-xs font-bold">x{article.quantity}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    {customFields.map((field) => {
                      const clientFieldValue = selectedClient[field.name];
                      if (!clientFieldValue) return null;

                      return (
                        <div key={field.name} className="mb-4">
                          <div className="text-sm font-bold mb-2">
                            {field.dashboard_title}
                          </div>
                          <div className="text-sm font-light flex">
                            {clientFieldValue}

                            {field.name === "delivery_address" && (
                              <div onClick={() => openInGoogleMaps(clientFieldValue)}
                                className="bg-blue-200 ml-5 text-center cursor-pointer text-blue-600 w-1/3 flex items-center justify-center px-2 py-1 rounded-lg">
                                <p className="font-semibold text-xs">Ouvrir sur Google Maps</p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="text-center md:text-left md:w-1/12 mb-2 text-center md:mb-0 font-semibold text-xs">
                {messages.some((message) => message.client_id === client.id) ? (
                  <div
                    onClick={() => handleBubbleClick(client)}
                    className="relative bg-blue-200 text-blue-700 flex items-center justify-center px-2 py-1 rounded-lg cursor-pointer"
                  >
                    <p className="font-semibold">Ouvrir</p>
                    {unreadMessages.some(
                      (message) => message.client_id === client.id
                    ) && (
                        <span className="absolute top-50 right-2 w-3 h-3 bg-red-500 rounded-full"></span>
                      )}
                  </div>
                ) : (
                  <div onClick={() => handleBubbleClick(client)} className="bg-yellow-200 cursor-pointer text-yellow-600 flex items-center justify-center px-2 py-1 rounded-lg">
                    <p className="font-semibold">Créer</p>
                  </div>
                )}
              </div>

              <div className="text-center md:text-left md:w-1/12 mb-2 md:mb-0 font-semibold text-xs text">
                {client.status === "pending" &&
                  !messages.some(
                    (message) =>
                      message.type === "request" &&
                      message.client_id === client.id
                  ) ? (
                  <div className="flex justify-center w-full md:w-auto mt-2 md:mt-0 relative">
                    <button
                      onClick={() => handleActionClick(client.id)}
                      className="bg-pink-100 text-pink-600 flex items-center w-full justify-center px-2 py-1 rounded-lg"
                    >
                      <p className="font-semibold">Actions</p>
                      <FaChevronDown className="ml-2" />
                    </button>
                    {showActions === client.id && (
                      <div className="absolute bg-pink-100 border py-2 rounded-lg mt-2 top-5 px-2 z-10 right-0">
                        <button
                          onClick={() => handleRejectClick(client.id)}
                          className="block w-full py-1 px-4 bg-white text-red-600 hover:bg-red-300 rounded-lg my-2"
                        >
                          Refuser
                        </button>
                        <button
                          onClick={() => handleRefundClient(client.id)}
                          className="block w-full py-1 px-4 bg-white text-blue-700 mb-2 hover:bg-blue-300 rounded-lg"
                        >
                          Client confirmé
                        </button>
                        <button
                          onClick={() => handleApproveClick(client.id, client)}
                          className="block w-full py-1 px-4 bg-white text-green-700 hover:bg-green-300 rounded-lg"
                        >
                          Approuver
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  translateStatus(client.status, client.id)
                )}
              </div>
            </div>
          ))}
        </div>

        {error && <p className="font-bold mb-4 text-center mt-8">{error}</p>}
        {showModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-96">
              <h2 className="text-xl font-semibold mb-4">Raison du rejet</h2>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="w-full h-24 p-2 border rounded resize-none focus:border-transparent focus:ring-blue-500"
              />
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleModalClose}
                  className="py-2 px-4 font-semibold bg-gray-400 text-gray-100 rounded mr-2"
                >
                  Annuler
                </button>
                <button
                  onClick={handleModalSubmit}
                  className="py-2 px-4 bg-red-600 text-red-100 font-semibold rounded"
                >
                  Transmettre
                </button>
              </div>
            </div>
          </div>
        )}
        {showRevenueModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="bg-white rounded-lg p-6 w-11/12 md:w-1/2"
            >
              <h2 className="font-semibold mb-4 text-xl">
                Informations complémentaires
              </h2>
              <div className="mb-4">
                <label className="block mb-2 font-semibold text-sm">
                  Chiffre d'affaires généré grâce à la vente ?
                </label>
                <div className="flex items-center space-x-4">
                  <input
                    type="number"
                    {...register("revenue", { 
                      required: currentUser.sector === "PIDEV" ? "Ce champ est requis" : false 
                    })}
                    className="w-full p-2 border rounded focus:border-transparent focus:ring-blue-500 font-semibold"
                    placeholder="Entrer le montant"
                    onChange={(e) => {
                      if (currentUser.sector === "PIDEV") {
                        const commission = calculateCommission(e.target.value);
                        const percentage = ((commission / Number(e.target.value)) * 100).toFixed(2);
                        setCommissionInfo({ amount: commission, percentage });
                      }
                    }}
                  />
                  <span className="text-sm font-semibold">€</span>
                </div>
                {errors.revenue && (
                  <span className="text-red-500 text-sm">{errors.revenue.message}</span>
                )}
                {currentUser.sector === "PIDEV" && (
                  <div className="mt-2 text-sm text-green-600">
                    Commission: {commissionInfo.amount}€ ({commissionInfo.amount > 0 ? commissionInfo.percentage : 0}% du CA)
                  </div>
                )}
              </div>

              {currentUser.sector === "PIDEV" ? (
                <div className="mb-4">
                  <label className="block mb-2 font-semibold text-sm">
                    Type de prestation
                  </label>
                  <select
                    {...register("prestationType", { required: "Ce champ est requis" })}
                    className="w-full p-2 border rounded focus:border-transparent focus:ring-blue-500 font-semibold"
                  >
                    <option value="">Sélectionner une prestation</option>
                    <option value="Broderie">Broderie</option>
                    <option value="Signalétique">Signalétique</option>
                    <option value="Site internet">Site internet</option>
                    <option value="Vitrophanie">Vitrophanie</option>
                    <option value="Marquage véhicule">Marquage véhicule</option>
                    <option value="Vitre teintée">Vitre teintée</option>
                  </select>
                  {errors.prestationType && (
                    <span className="text-red-500 text-sm">{errors.prestationType.message}</span>
                  )}
                </div>
              ) : (
                <div className="mb-4">
                  <label className="block mb-2 font-semibold text-sm">
                    Le remboursement est-il un <strong>Panier A</strong> ou <strong>Panier Mixte</strong> ?
                  </label>
                  <div className="flex space-x-4">
                    <label>
                      <input
                        type="radio"
                        {...register("isHealth100", { required: true })}
                        value="Oui"
                        className="mr-2"
                      />
                      Oui
                    </label>
                    <label>
                      <input
                        type="radio"
                        {...register("isHealth100", { required: true })}
                        value="Non"
                        className="mr-2"
                      />
                      Non
                    </label>
                  </div>
                  {errors.isHealth100 && (
                    <span className="text-red-500">Ce champ est requis</span>
                  )}
                </div>
              )}

              <div className="mb-4">
                <label className="block mb-2 font-semibold text-sm">
                  Souhaitez vous envoyer un sms au client pour devenir parraineur ?
                </label>
                <div className="flex space-x-4">
                  <label>
                    <input
                      type="radio"
                      {...register("sendSms", { required: true })}
                      value="Oui"
                      className="mr-2"
                    />
                    Oui
                  </label>
                  <label>
                    <input
                      type="radio"
                      {...register("sendSms", { required: true })}
                      value="Non"
                      className="mr-2"
                    />
                    Non
                  </label>
                </div>
                {errors.sendSms && (
                  <span className="text-red-500">Ce champ est requis</span>
                )}
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleRevenueModalClose}
                  className="py-2 px-4 font-semibold bg-gray-400 text-gray-100 rounded mr-2 hover:bg-gray-500"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
                >
                  Valider
                </button>
              </div>
            </form>
          </div>
        )}
        {drawerOpen && (
          <div className="fixed inset-0 flex">
            <div className="flex-grow" onClick={closeDrawer}></div>
            <div className="bg-gray-100 w-full md:w-1/3 h-full p-6 shadow-lg relative flex flex-col">
              <button onClick={closeDrawer} className="absolute right-6">
                ❌
              </button>
              <h2 className="text-xl font-bold mb-4">
                Client - {selectedClient.first_name}{" "}
                {selectedClient.last_name}
              </h2>
              <div className="flex-grow mt-4 overflow-y-auto custom-scrollbar rounded-lg bg-white pb-20 px-8 py-8">
                <div className="flex flex-col space-y-4">
                  {selectedClientMessages?.map((message, index, array) => {
                    const previousMessage = array[index - 1];
                    const showSender =
                      !previousMessage ||
                      previousMessage.sender_id !== message.sender_id;
                    const showRequestMessage =
                      message.type === "request" &&
                      message.sender_id === currentUser.id;
                    return (
                      <div key={message.id}>
                        <div
                          className={`flex flex-col ${message.sender_id === currentUser.id
                            ? "items-end"
                            : "items-start"
                            }`}
                        >
                          {showSender && (
                            <p className="text-xs mb-2">
                              {message.sender_id === currentUser.id
                                ? `${currentUser.first_name} ${currentUser.last_name}`
                                : selectedClient?.user_info || ""}
                            </p>
                          )}
                          <div
                            className={`py-2 px-4 rounded-lg max-w-xs ${message.sender_id === currentUser.id
                              ? "bg-blue-200"
                              : "bg-gray-200"
                              }`}
                          >
                            <p className="font-semibold">{message.content}</p>
                          </div>
                          <p className="text-right text-xs mt-1">
                            {formatDateTime(message.created_at)}
                          </p>
                        </div>
                        {showRequestMessage && (
                          <p className="text-center mt-4 mb-2 text-sm italic">
                            Une demande de rejet du client a été demandée.
                            <br />
                            {selectedClient.user_info} a été informé, dans
                            l'attente de sa décision.
                          </p>
                        )}
                      </div>
                    );
                  })}
                  {selectedClient.status !== "pending" &&
                    selectedClient.status !== "refund" &&
                    selectedClient.status !== "approved" &&
                    selectedClient.status !== "payed"
                    && (
                      <p className="text-center mt-4  text-sm italic">
                        {selectedClient.status === "rejected"
                          ? `${selectedClient.user_info} a accepté la demande de refus.`
                          : `${selectedClient.sponsor_name} a refusé votre demande l'équipe Fideliz va intervenir sur le dossier dans moins de 24h.`}
                      </p>
                    )}
                </div>
              </div>
              {(selectedClient.status === "pending" ||
                selectedClient.status === "refund" || selectedClient.status === "payed") && (
                  <div className="w-full px-8">
                    <div
                      style={{ width: "calc(100% - 48px)" }}
                      className="absolute mx-auto bottom-6 rounded-lg left-0 right-0 p-4 bg-white shadow-lg flex items-center"
                    >
                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="flex-grow border rounded p-2 text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Écrire un message..."
                      />
                      <button
                        onClick={handleSendMessage}
                        className="ml-2 px-8 py-2 bg-blue-500 text-white rounded font-bold"
                      >
                        Envoyer
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </Page>
  );
}

export default OpticianDashboard;

