import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { FaUserFriends, FaGift, FaShoppingCart } from "react-icons/fa";
import authService from "../services/authService";
import { useAuth } from "../../../context/AuthContext";
import api from "../../../services/api.js";
import Logo from "../../../common/assets/blue-logo.svg";

const TOTAL_STEPS = 3;

const RegisterUser: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();
  const location = useLocation();
  const [apiError, setApiError] = useState(false);
  const [step, setStep] = useState(0);

  const password = watch("password", "");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const signature = params.get("signature");
    api
      .get(`/auth/sign-up?signature=${signature}`)
      .then(() => {
        setValue("signature", signature);
      })
      .catch((error) => {
        console.error("Error verifying signature", error);
        setApiError(true);
      });
  }, [location.search, setValue]);

  const onSubmit = async (data: any) => {
    try {
      const response = await authService.registerUser(data);
      setCurrentUser(response.user);
      navigate("/default");
    } catch (error: any) {
      console.error("Registration failed", error);
      const errorMessage =
        error.response?.data?.message ||
        "Une erreur est survenue, veuillez réessayer.";
      toast.error(errorMessage);
    }
  };

  const handleNext = () => {
    if (step < TOTAL_STEPS) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else if (step === 1) {
      setStep(0);
    }
  };

  const isLastStep = step === TOTAL_STEPS;

  const handleFormSubmit = (data: any) => {
    if (isLastStep) {
      onSubmit(data);
    } else {
      handleNext();
    }
  };

  if (apiError) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="bg-white p-8 rounded-lg w-full max-w-md text-center">
          <h1 className="text-3xl font-bold mb-6">Fideliz</h1>
          <p className="text-gray-700 font-semibold mb-4">
            Ce lien a déjà été utilisé ou est expiré.
            <br />
            Veuillez contacter Fideliz pour plus d'informations.
          </p>
          <div
            onClick={() => navigate("/login")}
            className="w-1/2 py-2 px-4 bg-blue-500 text-white font-semibold text-center mx-auto rounded-lg mt-4 cursor-pointer"
          >
            <p>Se connecter</p>
          </div>
        </div>
      </div>
    );
  }

  // Calcul de la largeur de la barre de progression
  let progressWidth = 0;
  if (step > 0) {
    progressWidth = (step / TOTAL_STEPS) * 100;
  }

  return (
    <div className="flex flex-col items-center min-h-screen bg-white">
      <Toaster position="top-center" reverseOrder={false} />
      {/* Header */}
      <div
        style={{ borderColor: "#3B81F6" }}
        className="w-full bg-white flex justify-center items-center border-b-2 pb-4 mt-4"
      >
        <img src={Logo} style={{ marginLeft: 15 }} alt="Logo" className="h-5" />
      </div>

      <div className="bg-white w-full max-w-md p-6 rounded-lg">
        {step === 0 && (
          <div className="flex flex-col items-center justify-center text-center">

            <div className="flex flex-col space-y-8">
              <div
                style={{ backgroundColor: "#EAF8FF" }}
                className="flex p-5 px-8  rounded flex-col items-center space-y-2"
              >
                <FaUserFriends className="text-blue-500 text-2xl" />
                <p className="text-lg font-semibold">Parrainez vos proches</p>
                <p className="text-sm">
                  Recommandez votre opticien à vos amis, votre famille ou vos
                  collègues.
                </p>
              </div>

              <div
                style={{ backgroundColor: "#EAF8FF" }}
                className="flex p-5 px-8 rounded flex-col items-center space-y-2"
              >
                <FaGift className="text-blue-500 text-2xl" />
                <p className="text-lg font-semibold">Gagnez des récompenses</p>
                <p className="text-sm">
                  Pour chaque personne parrainée qui concrétise sa visite, vous
                  recevez une somme d'argent.
                </p>
              </div>

              <div
                style={{ backgroundColor: "#EAF8FF" }}
                className="flex p-5 px-8  rounded flex-col items-center space-y-2"
              >
                <FaShoppingCart className="text-blue-500 text-2xl" />
                <p className="text-lg font-semibold">Profitez de vos gains</p>
                <p className="text-sm">
                  Utilisez vos récompenses dans plus de 30 000 enseignes grâce à
                  une carte cadeau Glady, ou directement chez votre opticien.
                </p>
              </div>
            </div>

            <button
              style={{ backgroundColor: "#3B81F6" }}
              className="w-full text-white font-bold py-3 px-4 rounded mt-4"
              onClick={() => setStep(1)}
            >
              S'inscrire
            </button>
          </div>
        )}

        {step > 0 && (
          <>
            <h2 className="text-xl font-bold mb-4 text-center">Créér votre compte</h2>
            <div className="w-full bg-gray-300 h-2 rounded mb-6">
              <div
                className="h-2 bg-blue-500 rounded"
                style={{ width: `${progressWidth}%` }}
              ></div>
            </div>

            <form onSubmit={handleSubmit(handleFormSubmit)}>
              {step === 1 && (
                <div className="flex flex-col space-y-4">
                  <div>
                    <label className="block mb-2 font-semibold">Prénom</label>
                    <input
                      type="text"
                      className="w-full border rounded p-2"
                      {...register("firstName", {
                        required: "Le prénom est requis",
                        pattern: {
                          value: /^[A-Za-zÀ-ÿ '-]+$/,
                          message: "Le prénom ne peut pas contenir de chiffres",
                        },
                      })}
                    />
                    {errors.firstName && (
                      <p className="text-red-500 text-sm">
                        {errors.firstName.message as string}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block mb-2 font-semibold">Nom</label>
                    <input
                      type="text"
                      className="w-full border rounded p-2"
                      {...register("lastName", {
                        required: "Le nom est requis",
                        pattern: {
                          value: /^[A-Za-zÀ-ÿ '-]+$/,
                          message: "Le nom ne peut pas contenir de chiffres",
                        },
                      })}
                    />
                    {errors.lastName && (
                      <p className="text-red-500 text-sm">
                        {errors.lastName.message as string}
                      </p>
                    )}
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="flex flex-col space-y-4">
                  <div>
                    <label className="block mb-2 font-semibold">Email</label>
                    <input
                      type="email"
                      className="w-full border rounded p-2"
                      {...register("email", {
                        required: "L'email est requis",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Adresse email invalide",
                        },
                      })}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm">
                        {errors.email.message as string}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block mb-2 font-semibold">
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      className="w-full border rounded p-2"
                      {...register("password", {
                        required: "Le mot de passe est requis",
                        minLength: {
                          value: 6,
                          message:
                            "Le mot de passe doit contenir au moins 6 caractères",
                        },
                      })}
                    />
                    {errors.password && (
                      <p className="text-red-500 text-sm">
                        {errors.password.message as string}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block mb-2 font-semibold">
                      Confirmer le mot de passe
                    </label>
                    <input
                      type="password"
                      className="w-full border rounded p-2"
                      {...register("confirmPassword", {
                        required: "La confirmation du mot de passe est requise",
                        validate: (value: string) =>
                          value === password ||
                          "Les mots de passe ne correspondent pas",
                      })}
                    />
                    {errors.confirmPassword && (
                      <p className="text-red-500 text-sm">
                        {errors.confirmPassword.message as string}
                      </p>
                    )}
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className="flex flex-col space-y-4">
                  <div>
                    <label className="block mb-2 font-semibold">
                      Numéro de téléphone
                    </label>
                    <input
                      type="tel"
                      className="w-full border rounded p-2"
                      {...register("phone", {
                        required: "Le numéro de téléphone est requis",
                        pattern: {
                          value: /^0[0-9]{9}$/,
                          message:
                            "Le numéro de téléphone doit commencer par 0 et contenir exactement 10 chiffres",
                        },
                        maxLength: {
                          value: 10,
                          message:
                            "Le numéro de téléphone doit contenir exactement 10 chiffres",
                        },
                      })}
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-sm">
                        {errors.phone.message as string}
                      </p>
                    )}
                  </div>

                  <div className="flex items-start mt-4">
                    <input
                      type="checkbox"
                      className="mt-1"
                      {...register("acceptCGU", {
                        required: "Vous devez accepter les CGU pour continuer",
                      })}
                    />
                    <label className="ml-2 text-sm">
                      J'accepte les{" "}
                      <a
                        href="https://www.fideliz.fr/cgu-particulier"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline"
                      >
                        Conditions Générales d'Utilisation
                      </a>
                    </label>
                  </div>
                  {errors.acceptCGU && (
                    <p className="text-red-500 text-sm">
                      {errors.acceptCGU.message as string}
                    </p>
                  )}
                </div>
              )}

              {/* Navigation des étapes */}
              <div className="flex justify-between mt-6">
                {step > 1 && (
                  <button
                    type="button"
                    onClick={handleBack}
                    className="bg-gray-300 text-gray-800 py-2 px-4 rounded"
                  >
                    Précédent
                  </button>
                )}
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded ml-auto"
                >
                  {isLastStep ? "S'inscrire" : "Suivant"}
                </button>
              </div>
            </form>

            <p className="mt-4 text-center font-semibold">
              Déjà un compte ?{" "}
              <a href="/login" className="text-blue-600 underline">
                Se connecter
              </a>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default RegisterUser;
